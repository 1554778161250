import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import EconomicCalendar from "../assets//images/economic-calendar.png";
import HolidaysCalendar from "../assets//images/holidays-calendar.png";
import fedRate from "../assets//images/fed-rate.png";
import currencyConvertor from "../assets//images/currency-convertor.png";
import fibonacciCalculator from "../assets//images/fibonacci-calculator.png";
import currencyCorrelation from "../assets//images/currency-correlation.png";
import pivotpointcalculator from "../assets//images/pivot-point-calculator.png";
import margincalculator from "../assets//images/margin-calculator.png";
import profitcalculator from "../assets//images/profit-calculator.png";
import currencyvolatility from "../assets//images/currency-volatility.png";
import currencyheatmap from "../assets//images/currency-heat-map.png";

const Tools = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Resources" }, { label: "Tools" }];

  const tools = [
    {
      title: "Economic Calendar",
      image: EconomicCalendar,
      link: "/resources/tools/economic-calendar",
      desc: "An Economic Calendar tracks key financial events like interest rates, GDP, and employment data, helping traders anticipate market movements.",
    },
    // {
    //   title: "Holidays Calendar",
    //   image: HolidaysCalendar,
    //   link: "/resources/tools/holidays-calendar",
    //   desc: "A Holiday Calendar lists global market holidays, helping traders plan by identifying non-trading days and potential low market activity.",
    // },
    {
      title: "Pip Calculator",
      image: fedRate,
      link: "/resources/tools/pip-calculator",
      desc: "The Pip Monitor Tool tracks market expectations for Federal Reserve interest rate changes, helping traders anticipate monetary policy shifts.",
    },
    {
      title: "Currency Convertor",
      image: currencyConvertor,
      link: "/resources/tools/currency-convertor",
      desc: "A Currency Converter calculates exchange rates between currencies, assisting traders and travelers with real-time currency conversions.",
    },
    {
      title: "Fibonacci Calculator",
      image: fibonacciCalculator,
      link: "/resources/tools/fibonacci-calculator",
      desc: "A Fibonacci Calculator computes key retracement and extension levels, aiding traders in identifying potential support and resistance areas.",
    },
    {
      title: "Currency Correlation",
      image: currencyCorrelation,
      link: "/resources/tools/currency-correlation",
      desc: "Currency Correlation measures the relationship between currency pairs, helping traders assess diversification and manage risk effectively.",
    },
    {
      title: "Pivot Point Calculator",
      image: pivotpointcalculator,
      link: "/resources/tools/pivot-point-calculator",
      desc: "A Pivot Point Calculator determines key support and resistance levels, helping traders identify potential market turning points.",
    },
    {
      title: "Margin Calculator",
      image: margincalculator,
      link: "/resources/tools/margin-calculator",
      desc: "A Margin Calculator helps traders determine the margin required to open and maintain a trading position.",
    },
    {
      title: "Profit Calculator",
      image: profitcalculator,
      link: "/resources/tools/profit-calculator",
      desc: "A Profit Calculator estimates potential profits or losses from trades, helping traders plan and evaluate strategies.",
    },
    {
      title: "Currency Volatility",
      image: currencyvolatility,
      link: "/resources/tools/currency-volatility",
      desc: "Currency Volatility measures the price fluctuations of currency pairs, helping traders assess risk and potential market movement.",
    },
    {
      title: "Currency Heat Map",
      image: currencyheatmap,
      link: "/resources/tools/currency-heat-map",
      desc: "A Currency Heat Map visually represents the strength of various currencies, helping traders quickly identify market trends and opportunities.",
    },
  ];
  return (
    <div className="products-page-main">
      <PageTitle title="Tools" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Tools</span>
                  <h2 className="theme-title">Trading Calculators</h2>
                  <p className="theme-description">
                    Trading calculators are essential for improving forex traders' analytical skills
                    and boosting their overall trading success, whether they are used for
                    calculating margins, evaluating risk-reward ratios, or deciding position sizes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="calculator-blocks">
            <div className="row">
              {tools.map((tool, index) => (
                <div className="col-12 col-xxl-3 col-lg-3 col-md-6 mb-4" key={index}>
                  <div className="box-outer text-center">
                    <div className="title">{tool.title}</div>
                    <div className="image">
                      <img src={tool.image} alt="icon" />
                    </div>
                    <div className="desc">{tool.desc}</div>
                    <div className="button">
                      <Link to={tool.link}>Use This Tool</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
