import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/common/PageTitle";

const Tool = () => {
  const params = useParams();
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState("10px");

  const parseText = (input) => {
    const words = input.split("-");
    const output = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

    return output;
  };

  const [breadcrumbs, setBreadcrumbs] = useState([
    { label: "Home", link: "/" },
    { label: "Resources" },
    { label: "Tools" },
  ]);
  const [title, setTitle] = useState("");
  const [frameSRC, setFrameSRC] = useState("");

  const tools = {
    "economic-calendar": {
      src: "https://widget.myfxbook.com/widget/calendar.html?lang=en&impacts=0,1,2,3&symbols=AED,AFN,ALL,AMD,ANG,AOA,ARS,AUD,AWG,AZN,BAM,BBD,BDT,BGN,BHD,BIF,BMD,BND,BOB,BRL,BSD,BTN,BWP,BYN,BZD,CAD,CDF,CHF,CLP,CNY,COP,CRC,CUC,CVE,CZK,DJF,DKK,DOP,DZD,EGP,ERN,ETB,EUR,FJD,FKP,GBP,GEL,GHS,GIP,GMD,GNF,GTQ,GYD,HKD,HNL,HRK,HTG,HUF,IDR,ILS,INR,IQD,IRR,ISK,JMD,JOD,JPY,KES,KGS,KHR,KMF,KPW,KRW,KWD,KYD,KZT,LAK,LBP,LKR,LRD,LSL,LYD,MAD,MDL,MGA,MKD,MMK,MNT,MOP,MRO,MUR,MVR,MWK,MXN,MYR,MZN,NAD,NGN,NIO,NOK,NPR,NZD,OMR,PAB,PEN,PGK,PHP,PKR,PLN,PYG,QAR,RON,RSD,RUB,RWF,SAR,SBD,SCR,SDG,SEK,SGD,SHP,SLL,SOS,SRD,SSP,STD,SYP,SZL,THB,TJS,TMT,TND,TOP,TRY,TTD,TWD,TZS,UAH,UGX,USD,UYU,UZS,VEF,VND,VUV,WST,XAF,XCD,XOF,XPF,YER,ZAR,ZMW",
      height: "500px",
    },
    // "holidays-calendar": {},
    "currency-convertor": {
      src: "https://ssltools.investing.com/currency-converter/?from=17&to=12&force_lang=1&with_powered_by=false",
      height: "400px",
    },
    "fibonacci-calculator": {
      src: "https://ssltools.investing.com/fibonacci-calculator/index.php?force_lang=1",
      height: "630px",
    },
    "currency-heat-map": {
      src: "https://widgets.myfxbook.com/widgets/heat-map.html?symbols=8,47,9,10,1234,11,103,12,46,1245,6,13,14,15,17,18,7,2114,19,20,21,22,1246,23,1,1233,107,24,25,4,2872,137,48,1236,1247,2012,2,1863,3240,26,49,27,28,2090,131,5,29,5779,31,34,3,36,37,38,2076,40,41,42,43,45,3005,3473,50,2115,2603,2119,1815,2521,51,5435,5079,1893&type=0",
      height: "500px",
    },
    "pip-calculator": {
      src: "https://ssltools.investing.com/pip-calculator/index.php?force_lang=1&acc=12",
      height: "750px",
    },
    "currency-correlation": {
      src: "https://widgets.myfxbook.com/widgets/market-correlation.html?rowSymbols=8,47,9,10,1234,11,103,12,46,1245,6,13,14,15,17,18,7,2114,19,20,21,22,1246,23,1,1233,107,24,25,4,2872,137,48,1236,1247,2012,2,1863,3240,26,49,27,28,2090,131,5,29,5779,31,34,3,36,37,38,2076,40,41,42,43,45,3005,3473,50,2115,2603,2119,1815,2521,51,5435,5079,1893&colSymbols=8,9,10,6,7,1,4,2,5,3&timeScale=1440",
      height: "500px",
    },
    "pivot-point-calculator": {
      src: "https://ssltools.investing.com/pivot-calculator/index.php?force_lang=1",
      height: "580px",
    },
    "margin-calculator": {
      src: "https://ssltools.investing.com/margin-calculator/index.php?force_lang=1&acc=12&pair=1",
      height: "440px",
    },
    "profit-calculator": {
      src: "https://ssltools.investing.com/profit-calculator/index.php?force_lang=1&acc=12&pair=1",
      height: "440px",
    },
    "currency-volatility": {
      src: "https://widgets.myfxbook.com/widgets/market-volatility.html?symbols=8,47,9,10,1234,11,103,12,46,1245,6,13,14,15,17,18,7,2114,19,20,21,22,1246,23,1,1233,107,24,25,4,2872,137,48,1236,1247,2012,2,1863,3240,26,49,27,28,2090,131,5,29,5779,31,34,3,36,37,38,2076,40,41,42,43,45,3005,3473,50,2115,2603,2119,1815,2521,51,5435,5079,1893&type=0",
      height: "500px",
    },
  };

  useEffect(() => {
    // const iframe = document.getElementById("casinoEventFrame");
    if (params.widget) {
      const title = parseText(params.widget);
      setTitle(title);
      setFrameSRC(tools?.[params.widget].src);
      setBreadcrumbs([
        { label: "Home", link: "/" },
        { label: "Resources" },
        { label: "Tools / " + title },
      ]);
      setIframeHeight(tools?.[params.widget].height);
    }
  }, [params.widget]);

  return (
    <div className="products-page-main">
      <PageTitle title="Tools" breadcrumbs={breadcrumbs} />
      <div className="benefits-section page-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="left-content">
                <div className="page-title">
                  <span>Tools</span>
                  <h2 className="theme-title">{title}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <iframe
                  id="toolsFrame"
                  ref={iframeRef}
                  src={frameSRC}
                  title={title}
                  style={{
                    width: "100%",
                    height: iframeHeight,
                    border: "none",
                    transition: "height 0.2s ease",
                  }}
                  // onLoad={handleIfrmeLoad}
                  // onLoad={resizeIframe}
                  sandbox="allow-same-origin allow-scripts"
                  xframeoptions="SAMEORIGIN"
                  allowtransparency="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tool;
